import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';

export class AuthenticationError extends Error {
  constructor(message: string) {
      super(message); // 'Error' breaks prototype chain here
      this.name = 'AuthenticationError';
      Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export const KNOWN_DOMAINS = [
  'shieldgps.co',
  'www.shieldgps.co',
  'shieldgps.com',
  'www.shieldgps.com',
  'api.admin.shieldgps.com',
  'localhost',
  'shieldgps.test',
];

interface DecodedToken {
  exp: number;
}

let isRefreshing = false; // Flag to track if token refresh is in progress
let refreshPromise: Promise<void> | null = null; // Promise to track the token refresh process

export const ACCESS_TOKEN_STORAGE_KEY = "co.shieldgps.accessToken";
export const REFRESH_TOKEN_STORAGE_KEY = "co.shieldgps.refreshToken";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;  // Custom property to handle retry logic

  /* By default all request as assumed to require authentication (i.e the presence
   * of valid access/refresh tokens). If such tokens are not available, the request
   * is rejected before a request is even made. Use the skipAuth flag to bypass this
   * behavior and allow requests to be made without authentication. 
   * 
   * E.g POST /auth/login would use this.
   */
  skipAuth?: boolean;
}

// Utility functions
function isTokenExpired(token: string | null): boolean {
  try {
    if (!token) return true;
    const decoded: DecodedToken = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
}

function getAccessToken(): string | null {
  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  return token && !isTokenExpired(token) ? token : null;
}

function getRefreshToken(): string | null {
  const token = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  return token && !isTokenExpired(token) ? token : null;
}

async function initiateTokenRefresh(): Promise<void> {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    // Mo refresh token available so we can't possibly refresh the access token
    throw new AuthenticationError('No refresh token available.');
  }

  try {
    // Intentionally not using the ShieldAxiosClient here to avoid infinite loops (due to the interceptor)
    const response = await axios.post('/auth/refresh', { refreshToken }, {
      baseURL: API_BASE_URL,
      headers: defaultRequestHeaders(),
    });
    // 25Apr24: TODO: I saw an instance where the response to the request was 200 but there
    // was no body. That resulted in the token values 'undefined' being written. That
    // then resulted in every future API request failing because getAccessToken() above failed
    // to decode and threw an exception.

    if (response.data.accessToken && response.data.refreshToken) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, response.data.accessToken);
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, response.data.refreshToken);
    }
  } catch (error) {
      Sentry.captureException(error, {
        tags: { errorCategory: 'auth-refresh-error' },
        extra: { info: 'Unable to refresh auth tokens. 401 will trigger logout' }
      });

    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      throw new AuthenticationError('Refresh token rejected.');
    } 

    throw error;
  }
}

/**
 * Will perform a token refresh if there's not already one in process (
 * and assuming we have a refresh token to work with)
 * 
 * @returns 
 */
function refreshTokensIfPossible(): Promise<void> {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = initiateTokenRefresh().finally(() => {
      isRefreshing = false;
      refreshPromise = null;
    });
  }
  return refreshPromise!;
}

/**
 * Generate standard consistent request headers.
 * 
 * @returns 
 */
function defaultRequestHeaders(): Record<string, string | undefined> {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-APP-VERSION': process.env.VERSION,
    'X-APP-PLATFORM': process.env.PLATFORM,
    'X-APP-HTTPCLIENT_VERSION': 'v1',
  };
}

export const ShieldAxiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: defaultRequestHeaders(),
});

function isCrossOriginRequest(config: CustomAxiosRequestConfig): boolean {
  const hostname = config.url ? new URL(config.url, config.baseURL).hostname : '';
  return !KNOWN_DOMAINS.includes(hostname);
}

/**
 * This outbound request interceptor attempts to add an Authorization header. This in turn may 
 * trigger and block on a token refresh. Only one refresh is allowed at a time.
 */
ShieldAxiosClient.interceptors.request.use(async (config: CustomAxiosRequestConfig) => {
  // Don't attach headers if the request is to a different domain
  if (config.skipAuth || isCrossOriginRequest(config)) return config;

  const accessToken = getAccessToken();

  if (!accessToken) {
    await refreshTokensIfPossible();
    config.headers!.Authorization = `Bearer ${getAccessToken()}`;
  } else {
    config.headers!.Authorization = `Bearer ${accessToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

/**
 * A 401 response will can also trigger a token reference (as long as the 401 didn't 
 * come from the /auth/refresh API)
 */
ShieldAxiosClient.interceptors.response.use((response) => {
  return response;
}, async (error: AxiosError & { config: CustomAxiosRequestConfig }) => {
  const originalRequest = error.config;

  // Check if the error is 401, not previously retried, and not a request to /auth/refresh
  if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.url?.endsWith('/auth/refresh')) {
    originalRequest._retry = true;
    await refreshTokensIfPossible();
    originalRequest.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return ShieldAxiosClient(originalRequest);  // retry the original request
  }

  return Promise.reject(error);
});
